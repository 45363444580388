import React, { memo } from 'react';
import {
  compose,
} from 'recompose';

import { selectCommunitiesMap, selectDomainsMap } from 'redux/communities/selectors';

import { FormattedMessage } from 'react-intl';
import FieldsGroupHeader from 'react/generic/form/fieldsGroup/header/FieldsGroupHeader';

import { productShape } from 'shapes/product';
import withCurrentUser from 'react/business/user/withCurrentUser/withCurrentUser.connect';
import { currentUserShape } from 'shapes/user';
import { change as changeAction } from 'redux-form';
import withComponentFromFormValues from 'react/pages/component/form/withComponentFromFormValues';
import manifest from './presentation.manifest';

import messages from './presentation.messages';
import connect from '../../../../../hoc/connectProxy';
import { selectFormParentProduct } from '../../../../../../redux/products/selectors';
import { selectBusinessUnitsMap } from '../../../../../../redux/businessUnits/selectors';
import { withComponentFormMode } from '../../Context';

const {
  fields: {
    stage: { component: StageField },
    name: { component: NameField },
    alias: { component: AliasField },
    logo: { component: LogoField },
    shortDescription: { component: ShortDescriptionField },
    presentation: { component: PresentationField },
    softwarePublisher: { component: SoftwarePublisher },
    hostingType: { component: HostingTypeField },
  },
} = manifest;

const enhancer = compose(
  withComponentFormMode(),
  connect(
    state => ({
      formParentProduct: selectFormParentProduct(state),
      communities: selectCommunitiesMap(state),
      domains: selectDomainsMap(state),
      businessUnits: selectBusinessUnitsMap(state),
    }
    ),
    dispatch => ({
      dispatch,
      change: changeAction,
    }
    ),
  ),
  withComponentFromFormValues,
  withCurrentUser,
  memo,

);

const ComponentFormPresentationForm = ({
  user: currentUser, component: componentFromForm, ...props
}) => (
  <div style={{ marginBottom: 160 }}>
    <FieldsGroupHeader
      title={<FormattedMessage {...messages.HEADER_TITLE_PRESENTATION} />}
      message={<FormattedMessage {...messages.COMPONENT_HEADER_MESSAGE_PRESENTATION} />}
    />

    <StageField {...props} />

    <NameField {...props} isComponent />

    <AliasField {...props} isComponent />

    <LogoField {...props} />

    <ShortDescriptionField {...props} />

    <PresentationField {...props} isComponent />
    <HostingTypeField {...props} />
    <SoftwarePublisher {...props} />
  </div>
);

ComponentFormPresentationForm.displayName = 'ComponentFormPresentationForm';

ComponentFormPresentationForm.propTypes = {
  user: currentUserShape.isRequired,
  component: productShape.isRequired,
};

export default enhancer(ComponentFormPresentationForm);
