import { defineMessages } from 'react-intl';

export default defineMessages({
  PRESENTATION: { id: 'component.titles.presentation', defaultMessage: 'Presentation' },
  TAGS: { id: 'component.titles.tags', defaultMessage: 'Tags' },
  BUSINESS_ACTIVITIES: { id: 'component.titles.businessActivities', defaultMessage: 'Business activities' },
  REVIEWS: { id: 'component.titles.reviews', defaultMessage: 'Feedbacks' },
  SUGGESTIONS: { id: 'component.titles.suggestions', defaultMessage: 'Feature suggestions' },
  EDIT_COMPONENT_BUTTON: { id: 'component.titles.editComponentButton', defaultMessage: 'Edit the component overview' },
  EDIT_ADDITIONAL_BUTTON: { id: 'component.titles.editAdditionalInfoButton', defaultMessage: 'Edit component additional information' },
  SEE_ALL_REVIEWS: { id: 'component.actions.seeAllReviews', defaultMessage: 'See all feedbacks' },
  COMPLETION_RATE: { id: 'component.completion.rate', defaultMessage: 'Completion rate { completionRate } %' },
  LAST_UPDATE: { id: 'component.lastUpdate', defaultMessage: 'Last update: ' },
  COMPLETION_RATE_TOOLTIP: { id: 'component.completion.rate.tooltip', defaultMessage: 'To understand {link}.' },
  COMPLETION_RATE_LINK: { id: 'component.completion.rate.tooltip.here', defaultMessage: 'how is the completion rate calculated' },
  COMPLETION_RATE_DQ_DASHBOARD: { id: 'component.completion.rate.dqdashboard', defaultMessage: 'Check our {link} to increase your completion rate.' },
  COMPLETION_RATE_DQ_DASHBOARD_LINK: { id: 'component.completion.rate.dqdashboard.here', defaultMessage: 'Data Quality Dashboard' },
});
